.container {
  position: relative;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box; }

.column, .columns {
  width: 100%;
  float: left;
  box-sizing: border-box; }

@media (min-width: 400px) {
  .container {
    padding: 0; } }

@media (min-width: 800px) {
  .container {
    padding: 0 1em; }
  .column, .columns {
    margin-left: 4%; }
  .column:first-child, .columns:first-child {
    margin-left: 0; }
  .one.column, .one.columns {
    width: 4.66666666667%; }
  .two.columns {
    width: 13.3333333333%; }
  .three.columns {
    width: 22%; }
  .four.columns {
    width: 30.6666666667%; }
  .five.columns {
    width: 39.3333333333%; }
  .six.columns {
    width: 48%; }
  .seven.columns {
    width: 56.6666666667%; }
  .eight.columns {
    width: 65.3333333333%; }
  .nine.columns {
    width: 74%; }
  .ten.columns {
    width: 82.6666666667%; }
  .eleven.columns {
    width: 91.3333333333%; }
  .twelve.columns {
    width: 100%;
    margin-left: 0; }
  .one-third.column {
    width: 30.6666666667%; }
  .two-thirds.column {
    width: 65.3333333333%; }
  .one-half.column {
    width: 48%; }
  .offset-by-one.column, .offset-by-one.columns {
    margin-left: 8.66666666667%; }
  .offset-by-two.column, .offset-by-two.columns {
    margin-left: 17.3333333333%; }
  .offset-by-three.column, .offset-by-three.columns {
    margin-left: 26%; }
  .offset-by-four.column, .offset-by-four.columns {
    margin-left: 34.6666666667%; }
  .offset-by-five.column, .offset-by-five.columns {
    margin-left: 43.3333333333%; }
  .offset-by-six.column, .offset-by-six.columns {
    margin-left: 52%; }
  .offset-by-seven.column, .offset-by-seven.columns {
    margin-left: 60.6666666667%; }
  .offset-by-eight.column, .offset-by-eight.columns {
    margin-left: 69.3333333333%; }
  .offset-by-nine.column, .offset-by-nine.columns {
    margin-left: 78%; }
  .offset-by-ten.column, .offset-by-ten.columns {
    margin-left: 86.6666666667%; }
  .offset-by-eleven.column, .offset-by-eleven.columns {
    margin-left: 95.3333333333%; }
  .offset-by-one-third.column, .offset-by-one-third.columns {
    margin-left: 34.6666666667%; }
  .offset-by-two-thirds.column, .offset-by-two-thirds.columns {
    margin-left: 69.3333333333%; }
  .offset-by-one-half.column, .offset-by-one-half.columns {
    margin-left: 52%; } }

html {
  font-size: 62.5%; }

body {
  font-size: 1.5em;
  line-height: 1.6;
  font-weight: 400;
  font-family: Raleway,HelveticaNeue,"Helvetica Neue",Helvetica,Arial,sans-serif;
  color: #222; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: 300; }

h1 {
  font-size: 4rem;
  line-height: 1.2;
  letter-spacing: -.1rem; }

h2 {
  font-size: 3.6rem;
  line-height: 1.25;
  letter-spacing: -.1rem; }

h3 {
  font-size: 3rem;
  line-height: 1.3;
  letter-spacing: -.1rem; }

h4 {
  font-size: 2.4rem;
  line-height: 1.35;
  letter-spacing: -.08rem; }

h5 {
  font-size: 1.8rem;
  line-height: 1.5;
  letter-spacing: -.05rem; }

h6 {
  font-size: 1.5rem;
  line-height: 1.6;
  letter-spacing: 0; }

@media (min-width: 800px) {
  h1 {
    font-size: 5rem; }
  h2 {
    font-size: 4.2rem; }
  h3 {
    font-size: 3.6rem; }
  h4 {
    font-size: 3rem; }
  h5 {
    font-size: 2.4rem; }
  h6 {
    font-size: 1.5rem; } }

p {
  margin-top: 0; }

a {
  color: #1EAEDB; }

a:hover {
  color: #0FA0CE; }

.button, button, input[type=button], input[type=reset], input[type=submit] {
  display: inline-block;
  height: 38px;
  padding: 0 30px;
  color: #555;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: .1rem;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #bbb;
  cursor: pointer;
  box-sizing: border-box; }

.button:focus, .button:hover, button:focus, button:hover, input[type=button]:focus, input[type=button]:hover, input[type=reset]:focus, input[type=reset]:hover, input[type=submit]:focus, input[type=submit]:hover {
  color: #333;
  border-color: #888;
  outline: 0; }

.button.button-primary, button.button-primary, input[type=button].button-primary, input[type=reset].button-primary, input[type=submit].button-primary {
  color: #FFF;
  background-color: #33C3F0;
  border-color: #33C3F0; }

.button.button-primary:focus, .button.button-primary:hover, button.button-primary:focus, button.button-primary:hover, input[type=button].button-primary:focus, input[type=button].button-primary:hover, input[type=reset].button-primary:focus, input[type=reset].button-primary:hover, input[type=submit].button-primary:focus, input[type=submit].button-primary:hover {
  color: #FFF;
  background-color: #1EAEDB;
  border-color: #1EAEDB; }

input[type=email], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=url], select, textarea {
  height: 38px;
  padding: 6px 10px;
  background-color: #fff;
  border: 1px solid #D1D1D1;
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box; }

input[type=email], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=url], textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

textarea {
  min-height: 65px;
  padding-top: 6px;
  padding-bottom: 6px; }

input[type=email]:focus, input[type=number]:focus, input[type=password]:focus, input[type=search]:focus, input[type=tel]:focus, input[type=text]:focus, input[type=url]:focus, select:focus, textarea:focus {
  border: 1px solid #33C3F0;
  outline: 0; }

label, legend {
  display: block;
  margin-bottom: .5rem;
  font-weight: 600; }

fieldset {
  padding: 0;
  border-width: 0; }

input[type=checkbox], input[type=radio] {
  display: inline; }

label > .label-body {
  display: inline-block;
  margin-left: .5rem;
  font-weight: 400; }

ul {
  list-style: circle inside; }

ol {
  list-style: decimal inside; }

ol, ul {
  padding-left: 0;
  margin-top: 0; }

ol ol, ol ul, ul ol, ul ul {
  margin: 1.5rem 0 1.5rem 3rem;
  font-size: 90%; }

li {
  margin-bottom: 1rem; }

code {
  padding: .2rem .5rem;
  margin: 0 .2rem;
  font-size: 90%;
  white-space: nowrap;
  background: #F1F1F1;
  border: 1px solid #E1E1E1;
  border-radius: 4px; }

pre > code {
  display: block;
  padding: 1rem 1.5rem;
  white-space: pre; }

td, th {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #E1E1E1; }

td:first-child, th:first-child {
  padding-left: 0; }

td:last-child, th:last-child {
  padding-right: 0; }

.button, button {
  margin-bottom: 1rem; }

fieldset, input, select, textarea {
  margin-bottom: 1.5rem; }

blockquote, dl, figure, form, ol, p, pre, table, ul {
  margin-bottom: 2.5rem; }

.u-full-width {
  width: 100%;
  box-sizing: border-box; }

.u-max-full-width {
  max-width: 100%;
  box-sizing: border-box; }

.u-pull-right {
  float: right; }

.u-pull-left {
  float: left; }

hr {
  margin-top: 3rem;
  margin-bottom: 3.5rem;
  border-width: 0;
  border-top: 1px solid #E1E1E1; }

.container:after, .row:after, .u-cf {
  content: "";
  display: table;
  clear: both; }

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

html, body {
  background: #F5F5F5;
  color: #101010;
  min-height: 100vh;
  background-size: 200vw;
  overscroll-behavior-y: none;
  overscroll-behavior-x: none; }

body {
  max-width: 100vw;
  overflow-x: hidden; }

img {
  width: 100%;
  max-width: 100%;
  filter: grayscale(100%) contrast(200%);
  mix-blend-mode: multiply;
  transition: filter 0.4s; }
  img:hover {
    filter: grayscale(0%) contrast(100%); }

svg {
  width: 100%;
  height: auto; }

.img {
  width: 100%;
  max-width: 100%; }

@media (max-width: 800px) {
  img {
    filter: grayscale(0%) contrast(100%); } }

@font-face {
  font-family: "AAHA-WEB";
  src: url("../public/fonts/AAHA-Regular.woff2") format("woff");
  font-weight: normal; }

@font-face {
  font-family: "Starling";
  src: url("../public/fonts/starling/Starling Book.woff2") format("woff");
  font-weight: normal; }

@font-face {
  font-family: "Starling";
  src: url("../public/fonts/starling/Starling Book Italic.woff2") format("woff");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: "Starling";
  src: url("../public/fonts/starling/Starling Bold.woff2") format("woff");
  font-weight: bold; }

body {
  font-family: "Starling", "Times New Roman", serif;
  font-size: 21px;
  line-height: 1.4em; }

::-moz-selection {
  /* Code for Firefox */
  background: #c00000;
  color: #F5F5F5; }

::selection {
  background: #c00000;
  color: #F5F5F5; }

a,
a:link,
a:visited {
  color: #c00000;
  text-decoration-color: #c00000 !important;
  text-decoration: underline; }

a:hover,
a:active {
  color: #c00000;
  text-decoration: none; }

.aaha-text {
  font-family: "AAHA-WEB";
  font-size: 0.95em;
  font-weight: normal !important;
  letter-spacing: 0.05em; }

.inverse a:link,
.inverse a:visited {
  color: #F5F5F5;
  text-decoration: underline; }

.inverse a:hover,
.inverse a:active {
  text-decoration: none; }

.truth a:link,
.truth a:visited {
  color: #F5F5F5; }

.red {
  color: #c00000; }

h3 {
  font-family: "Lettera", Courier, monospace;
  font-weight: normal;
  font-size: 1rem;
  padding: 0;
  margin: 0; }

h1,
h2,
h3,
h5 {
  font-family: "AAHA-WEB";
  color: #101010; }

#contact h1,
#contact h2,
#contact h3,
#contact h5 {
  color: #F5F5F5; }

h4 {
  font-size: 1em;
  font-weight: bold; }

h1 {
  font-size: 3rem; }

h2 {
  font-size: 4.5rem;
  letter-spacing: 0.01em;
  line-height: 1.1; }

h3 {
  font-size: 3rem;
  letter-spacing: 0.02em;
  line-height: 4rem; }
  h3.bigTitle {
    font-size: 10rem;
    line-height: 1;
    padding-bottom: 1rem; }
  h3.textCenter {
    text-align: center; }

h5 {
  font-size: 2.25rem;
  margin-top: -0.1em;
  padding-right: 0.75em;
  margin-bottom: 0; }

.exAlt1,
.exAlt2,
.exAlt3 {
  width: 0;
  visibility: hidden;
  overflow: hidden; }

.exAlt1:before,
.exAlt2:before,
.exAlt3:before {
  visibility: visible; }

.exAlt1:before {
  content: "\0777"; }

.exAlt2:before {
  content: "\0776"; }

.exAlt3:before {
  content: "\0778"; }

.noBreak {
  white-space: nowrap; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right;
  padding-right: 1em; }

.caption {
  font-size: 0.8em;
  line-height: 1.3;
  padding-bottom: 1em;
  font-weight: bold;
  font-style: italic; }

.teamTitle:link,
.teamTitle:visited {
  font-family: "AAHA-WEB";
  font-size: 1.2em;
  color: #101010;
  text-decoration: none; }

.teamTitle:hover {
  color: #c00000; }

.orgLinks {
  list-style-type: none;
  margin: 0;
  padding: 0; }
  .orgLinks li {
    margin: 0;
    padding: 0; }
    .orgLinks li a:link,
    .orgLinks li a:visited {
      color: #101010; }

button {
  background: #c00000;
  color: #F5F5F5;
  border: 1px solid #c00000;
  border-radius: 30px !important; }

button:active,
button:hover,
button:visited {
  color: #F5F5F5;
  color: #c00000;
  background: #F5F5F5;
  border: 1px solid #c00000;
  border-radius: 30px; }

.small {
  font-size: 0.75em;
  line-height: 1.3em;
  font-weight: bold; }

.manifesto {
  font-family: "AAHA-WEB";
  text-align: center;
  font-weight: normal;
  font-size: 4.75vw;
  line-height: 1em;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-48%); }
  .manifesto.fr {
    font-size: 3.75vw; }
  .manifesto:hover h2,
  .manifesto:hover .num {
    color: rgba(16, 16, 16, 0.3); }
  .manifesto h2 {
    font-weight: normal;
    display: inline;
    font-size: inherit;
    cursor: pointer;
    transition: color 0.4s;
    text-transform: uppercase; }
    .manifesto h2:hover {
      color: #c00000; }
      .manifesto h2:hover span.num {
        color: #c00000; }

/**** BEGIN ALT ****/
.manifesto-2 {
  font-family: "AAHA-WEB";
  text-align: left;
  font-weight: normal;
  font-size: 5.5vw;
  line-height: 0.9em;
  margin: 0;
  padding: 0;
  width: 100%;
  padding: 1em; }
  .manifesto-2:hover h2,
  .manifesto-2:hover .num {
    color: rgba(16, 16, 16, 0.3); }
  .manifesto-2 h2 {
    margin-left: -0.4em;
    font-weight: normal;
    font-size: inherit;
    line-height: 0.9;
    cursor: pointer;
    transition: color 0.4s; }
    .manifesto-2 h2:hover {
      color: #c00000; }
      .manifesto-2 h2:hover span.num {
        color: #c00000; }

.num {
  color: #c00000;
  display: inline-block;
  margin-right: 0.1em; }

label,
.label {
  font-family: aaha-web, monospace;
  text-transform: uppercase;
  display: inline-block;
  font-size: 0.8em;
  line-height: 1.2em;
  letter-spacing: 0.1em; }

.label li {
  padding: 0;
  margin: 0; }

.label--red {
  background: #c00000;
  color: #eeeed7;
  padding: 0.4em 1em 0.2em;
  border-radius: 1em;
  cursor: pointer;
  transition: background 0.3s; }
  .label--red:hover {
    background: #101010; }

ol {
  list-style: decimal; }

.temp-filter-header,
.temp-filter-item {
  font-family: aaha-web, monospace;
  text-transform: uppercase;
  font-size: 0.8em;
  line-height: 1.2em;
  letter-spacing: 0.1em;
  cursor: pointer; }

.temp-filter-item {
  display: none; }
  .temp-filter-item:hover {
    background-color: #c00000; }
  .temp-filter-item.open {
    display: block; }

section {
  background: #F5F5F5;
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding: 7em 0 0;
  border-bottom: 1px solid rgba(192, 0, 0, 0.5); }
  section.inverse {
    background: #101010;
    color: whitesmoke; }
  section#demands-2 {
    padding: 0; }

.container {
  max-width: 1700px; }

p {
  padding: 0;
  margin-bottom: 0.5em !important; }

.sticky {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 3em; }

.sticky-demo {
  position: fixed;
  bottom: 1rem;
  background: #c00000;
  color: #F5F5F5;
  padding: 0.25em 1em;
  font-family: "AAHA-WEB"; }

h1 {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1;
  transition: transform 0.4s; }
  h1.title-top {
    top: 0.75em; }
  h1.title-bottom {
    color: black;
    position: fixed;
    top: calc(100vh - 1.6em); }
  h1.fr {
    transform: rotate(180deg); }
  h1.title-top, h1.title-bottom {
    position: fixed;
    color: #c00000;
    width: 100%;
    z-index: 1;
    text-align: center;
    letter-spacing: 0.05em;
    pointer-events: all; }
    h1.title-top a:link, h1.title-bottom a:link {
      text-decoration: none; }

.title-top.invert,
.title-bottom.invert {
  color: #F5F5F5 !important; }

#lang {
  width: 80px;
  height: 30px;
  cursor: pointer;
  position: fixed;
  z-index: 12;
  bottom: 1em;
  right: 1em;
  transform-origin: 50% 50%;
  transition: all 0.4s; }
  #lang div {
    display: inline-block;
    border-radius: 2px;
    transition: all 0.4s;
    padding: 0.2em 0.3em 0;
    color: #c00000;
    font-family: aaha-web; }
    #lang div:hover, #lang div.active {
      color: rgba(245, 245, 245, 0.9);
      background: #c00000; }

#mb-2-c .rect1 {
  width: 30px;
  top: 0;
  left: 5px; }

#mb-2-c .rect2 {
  width: 20px;
  top: 20px;
  left: 10px; }

#mb-2-c .rect3 {
  width: 40px;
  top: 40px;
  left: 0; }

#mb-2-l .rect1 {
  width: 30px;
  top: 0;
  left: 0; }

#mb-2-l .rect2 {
  width: 20px;
  top: 20px;
  left: 0; }

#mb-2-l .rect3 {
  width: 40px;
  top: 40px;
  left: 0; }

#menu-button {
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: fixed;
  z-index: 12;
  top: 1em;
  left: 1em;
  transform-origin: 50% 50%; }
  #menu-button .menu-button-square {
    background: #c00000;
    width: 10px;
    height: 10px;
    position: absolute;
    transform-origin: 50% 50%;
    transition: all 0.4s; }
    #menu-button .menu-button-square.top-left {
      top: 0;
      left: 0; }
    #menu-button .menu-button-square.top-right {
      top: 0;
      right: 0; }
    #menu-button .menu-button-square.bottom-left {
      bottom: 0;
      left: 0; }
    #menu-button .menu-button-square.bottom-right {
      bottom: 0;
      right: 0; }
    #menu-button .menu-button-square.center {
      top: 10px;
      left: 10px;
      opacitY: 0; }
  #menu-button.active .menu-button-square.center {
    opacitY: 1; }
  #menu-button.active .menu-button-square,
  #menu-button:hover .menu-button-square {
    transform: rotate(45deg); }
  #menu-button.active .menu-button-square {
    background: #101010; }
  #menu-button.invert .menu-button-square {
    background: #F5F5F5; }
  #menu-button.invert.active .menu-button-square {
    background: #101010; }

#menu-fist {
  width: 30px;
  height: 30px;
  display: block;
  opacity: 0;
  cursor: pointer;
  position: fixed;
  z-index: 12;
  padding: 0;
  margin: 0;
  line-height: 0;
  top: 2rem;
  right: 2rem;
  font-size: 4em;
  font-family: "AAHA-WEB";
  fill: #c00000; }
  #menu-fist #top_fist {
    fill: #c00000;
    transition: fill 0.4s; }
    #menu-fist #top_fist:hover {
      fill: #101010; }
  #menu-fist.active::after {
    position: relative;
    top: -0.2em;
    content: "\d7"; }
  #menu-fist.active #top_fist {
    opacity: 0; }
  #menu-fist.active .menu-button-square {
    background: #101010; }
  #menu-fist.invert #top_fist {
    fill: #F5F5F5; }
  #menu-fist.invert.active #menu-fist.invert #top_fist {
    fill: #101010; }

#menu {
  position: fixed;
  z-index: 10;
  background: rgba(245, 245, 245, 0.9);
  top: 0;
  left: 0;
  user-select: none;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.4s;
  padding: 0; }
  #menu.active {
    opacity: 1;
    user-select: initial;
    pointer-events: initial; }
  #menu ul {
    list-style-type: none;
    margin-top: 5em;
    margin-left: 0.5em; }
    #menu ul li {
      font-family: "AAHA-WEB";
      color: #c00000;
      font-size: 15vh;
      line-height: 0.8;
      padding-bottom: 0.2em;
      cursor: pointer;
      transition: color 0.4s; }
      #menu ul li a,
      #menu ul li a:link,
      #menu ul li a:visited {
        color: #c00000;
        text-decoration: none;
        transition: color 0.4s; }
      #menu ul li a:hover {
        color: #101010; }

.socials {
  font-size: 4em;
  letter-spacing: 0.05em;
  padding-bottom: 0.5em; }
  .socials i {
    cursor: pointer;
    transition: color 0.4s; }
    .socials i:hover {
      color: #c00000; }

.filter-section {
  text-align: center;
  font-family: 'AAHA-WEB';
  display: inline-block;
  border: 1px solid #c00000;
  border-radius: 2em;
  background: #c00000;
  color: white;
  padding-right: 0.5em;
  font-size: 1.75rem;
  margin: 0 0.5em;
  padding-top: 0.5em; }
  .filter-section option {
    font-family: 'AAHA-WEB'; }

.slide-fullscreen {
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  background-color: rgba(245, 245, 245, 0.9); }
  .slide-fullscreen img {
    width: 100%;
    height: 100%;
    padding-top: 3vw;
    max-width: 94vw;
    max-height: 90vh;
    object-fit: contain;
    mix-blend-mode: normal;
    cursor: zoom-out;
    transition: none; }
    .slide-fullscreen img, .slide-fullscreen img:hover {
      filter: none; }
  .slide-fullscreen .caption {
    padding: 1em;
    text-align: center; }
  .slide-fullscreen .close {
    font-family: 'AAHA-WEB';
    font-size: 7.5rem;
    color: #c00000;
    position: absolute;
    top: 2.5rem;
    right: 2rem;
    cursor: pointer;
    transition: color 0.4s; }
    .slide-fullscreen .close:hover {
      color: #101010; }

.flickity-viewport {
  overflow: visible; }
  .flickity-viewport .slide {
    border-right: 3px solid rgba(16, 16, 16, 0);
    width: 100%; }
    .flickity-viewport .slide .demand-slide {
      width: 100%;
      height: 80vh;
      overflow: hidden;
      position: relative; }
      .flickity-viewport .slide .demand-slide img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        max-width: 100%;
        max-height: 600px;
        width: auto;
        height: auto;
        opacity: 0.3;
        cursor: zoom-in; }
    .flickity-viewport .slide .caption {
      opacity: 0;
      transition: 0.4s; }
    .flickity-viewport .slide.is-selected .caption {
      opacity: 1; }
    .flickity-viewport .slide.is-selected img {
      filter: grayscale(0) contrast(100%);
      opacity: 1; }

button.flickity-button {
  padding: 0;
  border: 1px solid #c00000;
  background: none;
  z-index: 10000; }
  button.flickity-button svg {
    fill: #c00000; }
  button.flickity-button:hover {
    border: 1px solid #c00000; }
    button.flickity-button:hover svg {
      fill: #c00000; }

#bottom_fist {
  display: inline-block;
  fill: #eeeed7;
  max-width: 200px; }

ul.actions {
  list-style-type: none;
  padding-top: 1em; }
  ul.actions li {
    margin-bottom: 1em;
    padding-bottom: 1em; }

table {
  width: 100%;
  border-spacing: 0;
  margin-bottom: 0; }
  table thead tr td {
    border-top: none !important;
    border-bottom: none; }
  table tr {
    padding: 0;
    margin: 0;
    visibility: visible;
    transition: height 0.4s;
    min-height: 50px;
    overflow: hidden; }
    table tr:hover h3 {
      color: #F5F5F5; }
    table tr td {
      padding: 0.25em; }
    table tr.hide {
      height: 0;
      visibility: collapse; }
    table tr:first-child td {
      border-top: 1px solid rgba(16, 16, 16, 0.3); }
    table tr td {
      margin: 0;
      padding: 0.5em;
      border-bottom: 1px solid rgba(16, 16, 16, 0.3); }
      table tr td:first-child {
        padding-left: 1em; }

.thirds {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  grid-column-gap: 1em; }
  .thirds svg {
    width: 100%;
    height: auto; }

#contact {
  position: relative;
  padding: 0; }
  #contact .row {
    position: absolute;
    top: 50vh;
    transform: translateY(-50%); }

.partners {
  margin-top: 2em;
  max-width: 40%; }
  .partners .footerLogo {
    fill: #101010;
    width: 100%;
    transition: fill 0.4s;
    height: auto;
    padding-bottom: 2em; }
    .partners .footerLogo:hover {
      fill: #c00000; }
    .partners .footerLogo svg {
      height: auto; }
    .partners .footerLogo #logoBiennale {
      width: 50%; }
    .partners .footerLogo #logoCCA,
    .partners .footerLogo #logoUW,
    .partners .footerLogo #logoSALA,
    .partners .footerLogo #logoRAIC {
      width: 80%; }
  .partners #contact {
    position: relative; }
    .partners #contact .row {
      padding: 0 2em;
      position: absolute;
      background: red;
      top: 50vh;
      transform: translateY(-50%); }
    .partners #contact h5 {
      color: whitesmoke; }
    .partners #contact li {
      margin-bottom: 0; }
    .partners #contact .footerList {
      font-family: 'AAHA-WEB';
      text-transform: uppercase; }

.sidebearing {
  width: calc(100vw / 2 - 1800px / 2); }

@media (max-width: 800px) {
  body {
    font-size: 18px; }
  #menu-button {
    top: 0.5em;
    left: 0.5em; }
  #menu-fist {
    top: 0.8rem;
    right: 0.5rem;
    width: 35px;
    height: 35px; }
  #lang {
    bottom: 0.5em;
    right: -0.75em; }
  #contact {
    text-align: center;
    position: relative; }
    #contact .socials {
      padding-bottom: 0.5rem; }
    #contact .row {
      padding: 0 2em;
      position: absolute;
      top: 50vh;
      transform: translateY(-50%); }
      #contact .row .text-right {
        text-align: center;
        padding-right: 0; }
  .manifesto {
    font-size: 1.75em;
    text-align: left;
    position: static;
    transform: translateY(0); }
    .manifesto .intro {
      display: block;
      padding: 0.5em 0.2em;
      color: #c00000;
      text-align: center; }
    .manifesto .num {
      width: 0.3em;
      text-align: right; }
    .manifesto.fr {
      font-size: 1.5em; }
    .manifesto h2 {
      text-align: left;
      display: block;
      padding: 0 0 0.3em 0.3em;
      margin: 0;
      line-height: 1.1;
      text-indent: -0.3em;
      text-transform: uppercase; }
  h1,
  h2,
  h3,
  h4 {
    text-align: center; }
  h1 {
    position: static;
    height: auto;
    margin: auto;
    margin-top: 1.7em;
    font-size: 2rem; }
    h1.title-top, h1.title-bottom {
      position: static;
      display: block; }
    h1.title-bottom {
      transform: rotate(0);
      position: static;
      padding: 0.5em; }
    h1.fr {
      transform: rotate(0); }
      h1.fr .title-bottom {
        display: block; }
      h1.fr .title-top {
        display: none; }
  h2 {
    font-size: 2em;
    line-height: 1; }
  h3 {
    padding-top: 1em; }
    h3.bigTitle {
      font-size: 3em; }
  section {
    padding: 1rem; }
    section#demands {
      min-height: 0;
      padding: 0; }
  .container {
    width: 100%;
    padding: 0; }
  section.demand {
    padding-top: 3em; }
  .sticky {
    position: static; }
  #menu ul li {
    font-size: 6rem; }
  .members thead {
    display: none; }
  .members tr {
    display: block;
    padding: 1em 0;
    border-bottom: 1px solid rgba(16, 16, 16, 0.3);
    height: auto; }
    .members tr:first-child {
      border-top: 1px solid rgba(16, 16, 16, 0.3); }
    .members tr:first-child td {
      border: none; }
  .members td {
    display: block;
    margin: 0;
    padding: 0;
    border: none;
    height: auto; }
    .members td:first-child {
      padding: 0; }
  .partners {
    max-width: 100%; } }

#loud-title {
  font-size: 80vh;
  position: fixed;
  width: 1500vh;
  top: 50%;
  transform: translateY(-50%);
  color: #101010;
  mix-blend-mode: screen;
  background-blend-mode: screen; }

#section-01:hover #loud-title {
  animation: moveLoudTitle 20s; }

@keyframes moveLoudTitle {
  0% {
    left: 0; }
  100% {
    left: -1500vh; } }

#hover-image2 {
  position: fixed;
  max-height: 80vh;
  width: auto;
  bottom: 0;
  right: 0; }

#switches {
  opacity: 0.1;
  position: fixed;
  top: 2em;
  right: 2em;
  background: #c00000;
  color: #eeeed7;
  width: 200px;
  height: 300px;
  z-index: 1000;
  padding: 1em; }
  #switches:hover {
    opacity: 1; }

.one-col-title {
  position: sticky;
  top: 5rem;
  left: 1rem;
  padding-top: 2rem;
  width: 20vw;
  height: 0; }

@media (max-width: 800px) {
  #switches {
    display: none; } }

.highlighted {
  color: #c00000; }
